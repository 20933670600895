import appConfig from '@/app/configs/appConfig.json';

/** Describes casper and eth related config data . */
export enum META_TAGS_CONFIG {
    GATEWAY_ADDRESS = 'gateway-address',
    CONCORDIUM_NODE_ADDRESS = 'concordium-node-address',
    CASPER_NODE_ADDRESS = 'casper-node-address',
    ETH_NODE_ADDRESS = 'eth-node-address',
    AVALANCHE_NODE_ADDRESS = 'avalanche-node-address',
    POLYGON_NODE_ADDRESS = 'polygon-node-address',
    BNB_NODE_ADDRESS = 'bnb-node-address',
    LIGHTLINK_NODE_ADDRESS = 'lightlink-node-address',
    STELLAR_NODE_ADDRESS = 'stellar-node-address',
    XVERSE_NODE_ADDRESS = 'xverse-node-address',
    GGX_NODE_ADDRESS = 'ggx-node-address'
};

/** Parses HTML meta tag and returns content. */
export function parseMetaTag(metaTagName: string): string {
    const metas = document.getElementsByTagName('meta');
    for (let i = appConfig.numbers.ZERO_NUMBER; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaTagName) {
            return metas[i].content;
        }
    }

    return '';
}
